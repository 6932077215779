import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Section } from "../../layout";
import { DesktopRightImage, Image, PhoneImage } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Image src="news/heightprofile_5.jpg" mdxType="Image" />
    <p>{`As the name already says, mountain biking is a mountain sport. The most obvious
characteristic of a mountain is the elevation. No matter if it is a small hill
or a high alpine peak, climbing and descending is what makes mountain biking.
Now you find all the numbers about climbs and descents on Trailguide.`}</p>
    <Image src="news/heightprofile_1.jpg" mdxType="Image" />
    <p>{`On the Computer and on the phone you find the height profile underneath the
map of the track. On the left of the chart you see the highest and lowest
altitude of the track. In the middle: the total climb and descent of the trail.`}</p>
    <Section mdxType="Section" />
    <DesktopRightImage src="news/heightprofile_3.jpg" mdxType="DesktopRightImage" />
    <h4>{`Moving marker`}</h4>
    <p>{`Click somewhere on the height profile. You then see the position along with
the distance and altitude on the map. Move your finger left and right to move
the position along the track.`}</p>
    <br />
    <p>{`Watch the point moving on the map, to
see get an idea of the direction when the singletrack is a closed loop.`}</p>
    <PhoneImage src="news/heightprofile_3.jpg" mdxType="PhoneImage" />
    <Section mdxType="Section" />
    <DesktopRightImage src="news/heightprofile_24.jpg" mdxType="DesktopRightImage" />
    <h4>{`Main map`}</h4>
    <p>{`On the main map, click on a trail and you see the height profile, total climb
and descent down in the info bar.`}</p>
    <br />
    <p>{`The height profile is automatically
generated out of the .gpx track and you don't have to do anything extra to
generate it.`}</p>
    <br />
    <p>{`The profile with the total climb and descent allows you to judge
the requirements of the trail much better and helps to find a track you want
to ride and can enjoy.`}</p>
    <PhoneImage src="news/heightprofile_24.jpg" mdxType="PhoneImage" />
    <Section mdxType="Section" />
    <DesktopRightImage src="news/heightprofile_4.jpg" mdxType="DesktopRightImage" />
    <h4>{`The beauty within`}</h4>
    <p>{`Wonder how Trailguide looks from inside? This beauty is the cogwheels
running underneath the hood, making sure Trailguide runs fast and smooth. And
in this case, creating the height profile for you.`}</p>
    <PhoneImage src="news/heightprofile_4.jpg" mdxType="PhoneImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      